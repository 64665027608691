import React from 'react'
import Inicio from '../sections/Inicio'
import Sobremim from '../sections/Sobremim'

export const Home = () => {
    return(
        <div>
            <Inicio />
            <Sobremim />
        </div>
    )
}

export default Home